import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';

import iconCard from './app_icon.svg'
import appStore from './app_store.svg'

export default function Home() {
    return (

        <Container>
      <Row style={{color: "white", textAlign: "center", marginTop: 50, marginBottom: 50}}>
        <h2 style={{fontFamily: "Varela Round"}}>Download BlackOut today!</h2>
      </Row>
      <img
            alt=""
            src={iconCard}
            width="300"
            height="300"
            style={{ }}
          />

      
      <Row style={{color: "white", textAlign: "center", marginTop: 50, marginBottom: 50}}>
        <h2 style={{fontFamily: "Varela Round"}}>How long will you survive?</h2>
      </Row>

      <Row className="justify-content-center">        

        <a href='https://play.google.com/store/apps/details?id=com.james.earl.blackout'>
          <img alt='Get it on Google Play' 
          src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
          height="87"/>
          </a>


          <a href='https://apps.apple.com/us/app/apple-store/id1665805011'>
          <img
          alt=""
          src={appStore}
          height="61"
        
        />
          </a>

      </Row>

      <Row style={{color: "white", textAlign: "center", marginTop: 50, marginBottom: 50}}>
        <h2 style={{fontFamily: "Varela Round"}}>Need help? Email:</h2>
        <h2 style={{fontFamily: "Varela Round"}}>support@sobogames.com</h2>
      </Row>

        

    </Container>
    );
}
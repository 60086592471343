
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';

import icon from './app_icon.svg'
import tricolor from './tricolor.svg'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container';
import Home from './Home.js'

document.body.style = "background: #0D0D0D;";

function App () {

  let component
  switch (window.location.pathname) {
    case "/":
      component = <Home/>
      break
  }

return (
  <div className="App">

    <Navbar variant="dark" style={{background: "#0D0D0D"}}>
        <Container>
          <Navbar.Brand href="/" style={{fontFamily: "Varela Round"}}>
            <img
              alt=""
              src={tricolor}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            Sobo Games
          </Navbar.Brand>

          <Navbar.Collapse className="justify-content-end">
          <Navbar.Text style={{fontFamily: "Varela Round"}}>
            <a href="https://www.freeprivacypolicy.com/live/8864064c-9cf0-407e-a95d-3ed299508f5a">Privacy Policy</a>
          </Navbar.Text>
        </Navbar.Collapse>
        </Container>
      </Navbar>

    {component}
    
  </div>    
);

}

export default App;

